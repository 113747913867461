.App {
  text-align: center;
  max-width: 800px;
  margin: 50px auto;
}

.results {
  margin: 20px 0;
  font-size: 16px;
}
